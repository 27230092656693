function i18n(lang) {
    var _this = this;
    this.lang = lang;
    this.fallbackLang = this.lang.includes('-') ? this.lang.split('-')[0] : this.lang;
    this.tran = function (key) {
        key = key.toLowerCase();
        if (tranTxt[_this.lang] && tranTxt[_this.lang][key]) {
            return tranTxt[_this.lang][key];
        } else if (tranTxt[_this.fallbackLang] && tranTxt[_this.fallbackLang][key]) {
            return tranTxt[_this.fallbackLang][key];
        } else if (standard[key]) {
            return standard[key];
        } else {
            return key;
        }
    };
}
var standard = {
    'danmaku-loading': 'Danmaku is loading',
    top: 'Top',
    bottom: 'Bottom',
    rolling: 'Rolling',
    'input-danmaku-enter': 'Input danmaku, hit Enter',
    'about-author': 'About author',
    'dplayer-feedback': 'DPlayer feedback',
    'about-dplayer': 'About DPlayer',
    loop: 'Loop',
    speed: 'Speed',
    'opacity-danmaku': 'Opacity for danmaku',
    normal: 'Normal',
    'please-input-danmaku': 'Please input danmaku content!',
    'set-danmaku-color': 'Set danmaku color',
    'set-danmaku-type': 'Set danmaku type',
    'show-danmaku': 'Show danmaku',
    'video-failed': 'Video load failed',
    'danmaku-failed': 'Danmaku load failed',
    'danmaku-send-failed': 'Danmaku send failed',
    'switching-quality': 'Switching to %q quality',
    'switched-quality': 'Switched to %q quality',
    ff: 'FF %s s',
    rew: 'REW %s s',
    'unlimited-danmaku': 'Unlimited danmaku',
    'send-danmaku': 'Send danmaku',
    setting: 'Setting',
    fullscreen: 'Full screen',
    'web-fullscreen': 'Web full screen',
    send: 'Send',
    screenshot: 'Screenshot',
    airplay: 'AirPlay',
    chromecast: 'ChromeCast',
    subtitle: 'Subtitle',
    off: 'Off',
    'show-subs': 'Show subtitle',
    'hide-subs': 'Hide subtitle',
    volume: 'Volume',
    live: 'Live',
    'video-info': 'Video info'
};
var tranTxt = {
    en: standard,
    'zh-cn': {
        'danmaku-loading': 'å¼¹å¹å è½½ä¸­',
        top: 'é¡¶é¨',
        bottom: 'åºé¨',
        rolling: 'æ»å¨',
        'input-danmaku-enter': 'è¾å¥å¼¹å¹\uFF0Cåè½¦åé',
        'about-author': 'å³äºä½è',
        'dplayer-feedback': 'æ­æ¾å¨æè§åé¦',
        'about-dplayer': 'å³äº DPlayer æ­æ¾å¨',
        loop: 'æ´èå¾ªç¯',
        speed: 'éåº¦',
        'opacity-danmaku': 'å¼¹å¹éæåº¦',
        normal: 'æ­£å¸¸',
        'please-input-danmaku': 'è¦è¾å¥å¼¹å¹åå®¹åå\uFF01',
        'set-danmaku-color': 'è®¾ç½®å¼¹å¹é¢è²',
        'set-danmaku-type': 'è®¾ç½®å¼¹å¹ç±»å',
        'show-danmaku': 'æ¾ç¤ºå¼¹å¹',
        'video-failed': 'è§é¢å è½½å¤±è´¥',
        'danmaku-failed': 'å¼¹å¹å è½½å¤±è´¥',
        'danmaku-send-failed': 'å¼¹å¹åéå¤±è´¥',
        'switching-quality': 'æ­£å¨åæ¢è³ %q ç»è´¨',
        'switched-quality': 'å·²ç»åæ¢è³ %q ç»è´¨',
        ff: 'å¿«è¿ %s ç§',
        rew: 'å¿«é %s ç§',
        'unlimited-danmaku': 'æµ·éå¼¹å¹',
        'send-danmaku': 'åéå¼¹å¹',
        setting: 'è®¾ç½®',
        fullscreen: 'å¨å±',
        'web-fullscreen': 'é¡µé¢å¨å±',
        send: 'åé',
        screenshot: 'æªå¾',
        airplay: 'æ çº¿æå±',
        chromecast: 'ChromeCast',
        subtitle: 'å­å¹',
        off: 'å³é­',
        'show-subs': 'æ¾ç¤ºå­å¹',
        'hide-subs': 'éèå­å¹',
        volume: 'é³é',
        live: 'ç´æ­',
        'video-info': 'è§é¢ç»è®¡ä¿¡æ¯'
    },
    'zh-tw': {
        'danmaku-loading': 'å½å¹è¼å¥ä¸­',
        top: 'é é¨',
        bottom: 'åºé¨',
        rolling: 'æ»¾å',
        'input-danmaku-enter': 'è¼¸å¥å½å¹\uFF0CEnter ç¼é',
        'about-author': 'éæ¼ä½è',
        'dplayer-feedback': 'æ­æ¾å¨æè¦åé¥',
        'about-dplayer': 'éæ¼ DPlayer æ­æ¾å¨',
        loop: 'å¾ªç°æ­æ¾',
        speed: 'éåº¦',
        'opacity-danmaku': 'å½å¹éæåº¦',
        normal: 'æ­£å¸¸',
        'please-input-danmaku': 'è«è¼¸å¥å½å¹å§å®¹å\uFF01',
        'set-danmaku-color': 'è¨­å®å½å¹é¡è²',
        'set-danmaku-type': 'è¨­å®å½å¹é¡å',
        'show-danmaku': 'é¡¯ç¤ºå½å¹',
        'video-failed': 'å½±çè¼å¥å¤±æ',
        'danmaku-failed': 'å½å¹è¼å¥å¤±æ',
        'danmaku-send-failed': 'å½å¹ç¼éå¤±æ',
        'switching-quality': 'æ­£å¨åæè³ %q ç«è³ª',
        'switched-quality': 'å·²ç¶åæè³ %q ç«è³ª',
        ff: 'å¿«é² %s ç§',
        rew: 'å¿«é %s ç§',
        'unlimited-danmaku': 'å·¨éå½å¹',
        'send-danmaku': 'ç¼éå½å¹',
        setting: 'è¨­å®',
        fullscreen: 'å¨è¢å¹',
        'web-fullscreen': 'é é¢å¨è¢å¹',
        send: 'ç¼é',
        screenshot: 'æªå',
        airplay: 'ç¡ç·æå±',
        chromecast: 'ChromeCast',
        subtitle: 'å­å¹',
        off: 'éé',
        'show-subs': 'é¡¯ç¤ºå­å¹',
        'hide-subs': 'é±èå­å¹',
        volume: 'é³é',
        live: 'ç´æ­',
        'video-info': 'å½±ççµ±è¨è¨æ¯'
    },
    'ko-kr': {
        'danmaku-loading': 'Danmakuë¥¼ ë¶ë¬ì¤ë ì¤ìëë¤.',
        top: 'Top',
        bottom: 'Bottom',
        rolling: 'Rolling',
        'input-danmaku-enter': 'Danmakuë¥¼ ìë ¥íê³  Enterë¥¼ ëë¥´ì¸ì.',
        'about-author': 'ë§ë ì´',
        'dplayer-feedback': 'í¼ëë°± ë³´ë´ê¸°',
        'about-dplayer': 'DPlayer ì ë³´',
        loop: 'ë°ë³µ',
        speed: 'ë°°ì',
        'opacity-danmaku': 'Danmaku ë¶í¬ëªë',
        normal: 'íì¤',
        'please-input-danmaku': 'Danmakuë¥¼ ìë ¥íì¸ì!',
        'set-danmaku-color': 'Danmaku ìì',
        'set-danmaku-type': 'Danmaku ì¤ì ',
        'show-danmaku': 'Danmaku íì',
        'video-failed': 'ë¹ëì¤ë¥¼ ë¶ë¬ì¤ì§ ëª»íìµëë¤.',
        'danmaku-failed': 'Danmakuë¥¼ ë¶ë¬ì¤ì§ ëª»íìµëë¤.',
        'danmaku-send-failed': 'Danmaku ì ì¡ì ì¤í¨íìµëë¤.',
        'Switching to': '',
        'Switched to': '',
        'switching-quality': 'ì í %q íì§',
        'switched-quality': 'ì í ë¨ %q íì§',
        ff: 'ìì¼ë¡ %s ì´',
        rew: 'ë¤ë¡ %s ì´',
        'unlimited-danmaku': 'ëìë Danmaku',
        'send-danmaku': 'Danmaku ë³´ë´ê¸°',
        setting: 'ì¤ì ',
        fullscreen: 'ì ì²´ íë©´',
        'web-fullscreen': 'ì¹ ë´ ì ì²´íë©´',
        send: 'ë³´ë´ê¸°',
        screenshot: 'íë©´ ìº¡ì³',
        airplay: 'ìì´íë ì´',
        chromecast: 'ChromeCast',
        subtitle: 'ë¶ì ',
        off: 'ëë¤',
        'show-subs': 'ìë§ ë³´ì´ê¸°',
        'hide-subs': 'ìë§ ì¨ê¸°ê¸°',
        Volume: 'ë³¼ë¥¨',
        live: 'ìë°©ì¡',
        'video-info': 'ë¹ëì¤ ì ë³´'
    },
    de: {
        'danmaku-loading': 'Danmaku lÃ¤dt...',
        top: 'Oben',
        bottom: 'Unten',
        rolling: 'Rollend',
        'input-danmaku-enter': 'DrÃ¼cke Enter nach dem EinfÃ¼gen vom Danmaku',
        'about-author': 'Ãber den Autor',
        'dplayer-feedback': 'DPlayer Feedback',
        'about-dplayer': 'Ãber DPlayer',
        loop: 'Wiederholen',
        speed: 'Geschwindigkeit',
        'opacity-danmaku': 'Transparenz fÃ¼r Danmaku',
        normal: 'Normal',
        'please-input-danmaku': 'Bitte Danmaku Inhalt eingeben!',
        'set-danmaku-color': 'Danmaku Farbe festlegen',
        'set-danmaku-type': 'Danmaku Typ festlegen',
        'show-danmaku': 'Zeige Danmaku',
        'video-failed': 'Das Video konnte nicht geladen werden',
        'danmaku-failed': 'Danmaku konnte nicht geladen werden',
        'danmaku-send-failed': 'Das senden von Danmaku ist fehgeschlagen',
        'switching-quality': 'Wechsle zur %q QualitÃ¤t',
        'switched-quality': 'Zur %q QualitÃ¤t gewechselt',
        ff: '%s s VorwÃ¤rts',
        rew: '%s s ZurÃ¼ck',
        'unlimited-danmaku': 'Unlimitiertes Danmaku',
        'send-danmaku': 'Sende Danmaku',
        setting: 'Einstellungen',
        fullscreen: 'Vollbild',
        'web-fullscreen': 'Browser Vollbild',
        send: 'Senden',
        screenshot: 'Screenshot',
        airplay: 'AirPlay',
        'show-subs': 'Zeige Untertitel',
        chromecast: 'ChromeCast',
        subtitle: 'Untertitel',
        off: 'SchlieÃung',
        'hide-subs': 'Verstecke Untertitel',
        volume: 'LautstÃ¤rke',
        live: 'Live',
        'video-info': 'Video Info'
    }
};
export {
    i18n
};